<template>
    <div ref="hoverElement" class="popover-personinfo bs-popover-auto fade shadow" style="background-color: cornsilk!important;" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex">
                <div style="font-size: smaller; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ workflowInfo.Title }}
                </div>
                <div class="me-2 ms-auto" :style="{ 'color': '#' + workflowInfo.SeverityColor }" style="cursor: default;" :title="workflowInfo.Severity ?? workflowInfo.SeverityLevel">⬤</div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{ $t('Loading...') }}
        </h3>
        <div class="popover-body p-2">
            <template v-if="isLoaded">
                <div class="row gx-5">
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Process") }}:</span>&nbsp;<span>{{ workflowInfo.Process }}</span>
                    </div>
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Step") }}:</span>&nbsp;<span>{{ workflowInfo.Step ?? workflowInfo.CurrentStep }}</span>
                    </div>
                    <div class="col-12 mb-0">
                        <span style="font-weight: 500;">{{ $t("Org Unit") }}:</span>&nbsp;<span>{{ workflowInfo.OrgUnit }}</span>
                    </div>
                    <div class="mt-1" style="max-height: 100px; overflow-y: hidden;">
                        {{ workflowInfo.Description }}
                    </div>
                    <div class="col-12 mb-0 mt-1 fst-italic">
                        {{ workflowInfo.CreatedBy }} {{ $formatDate(workflowInfo.Created, 'Short Date') }}
                    </div>
                </div>
            </template>
            <template v-else>
                {{ $t('Loading...') }}
            </template>
        </div>
    </div>
</template>

<script lang="ts">
const WorkflowCache = new Map<number, IWorkflowInfo>();

export interface IWorkflowInfo {
    ID: number;
    Title: string;
    CurrentStep: string;
    Step: string;
    StepResp: string;
    Process: string;
    PlannedStart: string;
    Severity: string;
    SeverityLevel: string;
    UniqueID: string;
    OrgUnit: string;
    SeverityColor: string;
    CreatedBy: string;
    Created: string;
    Description: string;
};

export interface IWorkflowHoverProps {
    workflowId?: number;
}
</script>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { API } from 'o365-modules';
import { $t, logger } from 'o365-utils';

const props = defineProps<IWorkflowHoverProps>();

const useScope = window['__navBarData']?.useScope ?? false;

const showContent = ref(false);
const isLoaded = ref(false);
const workflowInfo = ref<IWorkflowInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && workflowInfo.value?.ID != props.workflowId) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({ hoverElement, hoverHide, hoverShow });

async function loadWorkflowData(workflowId: number) {
    var result = null;
    if(useScope){
        result = await API.request({
            requestInfo: '/nt/api/data/aviw_Scope_Workflows',
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                "viewName": "aviw_Scope_Workflows",
                "distinctRows": false,
                "skip": 0,
                "fields": [
                    { name: "ID", type: "number" },
                    { name: "UniqueID", type: "string" },
                    { name: "Title", type: "string" },
                    { name: "OrgUnit", type: "string" },
                    { name: "CurrentStep", type: "string" },
                    { name: "StepResp", type: "string" },
                    { name: "Process", type: "string" },
                    { name: "PlannedStart", type: "string" },
                    { name: "SeverityLevel", type: "string" },
                    { name: "SeverityColor", type: "string" },
                    { name: "CreatedBy", type: "string" },
                    { name: "Created", type: "date" },
                    { name: "Description", type: "string" }
                ],
                "maxRecords": 1,
                "whereClause": `ID = ${workflowId}`,
                "masterDetailString": null,
                "filterString": null,
                "operation": "retrieve"
            })
        });
    } else {
        result = await API.request({
            requestInfo: '/nt/api/data/aviw_Workflow_Workflows',
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                "viewName": "aviw_Workflow_Workflows",
                "distinctRows": false,
                "skip": 0,
                "fields": [
                    { name: "ID", type: "number" },
                    { name: "UniqueID", type: "string" },
                    { name: "Title", type: "string" },
                    { name: "OrgUnit", type: "string" },
                    { name: "Step", type: "string" },
                    { name: "StepResp", type: "string" },
                    { name: "Process", type: "string" },
                    { name: "PlannedStart", type: "string" },
                    { name: "Severity", type: "string" },
                    { name: "SeverityColor", type: "string" },
                    { name: "CreatedBy", type: "string" },
                    { name: "Created", type: "date" },
                    { name: "Description", type: "string" }
                ],
                "maxRecords": 1,
                "whereClause": `ID = ${workflowId}`,
                "masterDetailString": null,
                "filterString": null,
                "operation": "retrieve"
            })
        });
    }
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    workflowInfo.value = null;
    if (props.workflowId == null) { return; }
    let workflowData = WorkflowCache.get(props.workflowId);
    if (workflowData == null) {
        try {
            workflowData = await loadWorkflowData(props.workflowId);
            if (workflowData == null) { return; }
            WorkflowCache.set(props.workflowId, workflowData);
        } catch (ex) {
            logger.error(ex);
            return;
        }
    }
    workflowInfo.value = workflowData;
    isLoaded.value = true;
}

watch(() => props.workflowId, (_newWorkflowId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
